import { ReactElement, useState, useEffect } from 'react';
import Link from 'next/link';
import Head from 'next/head';
import styled, { css } from 'styled-components';

import MainLayout from 'components/layout/mainLayout';


type Props = {};

const Error404Page: NextPageWithLayout<Props> = (props) => {

  return (
    <>
      <Head>
        <title>Page Not Found</title>
        <meta name="description" content="Page Not Found" />
      </Head>
      <StyledError className="errorPage">
        {/* <ErrorLayout statusCode="404" title="Oops... Something went wrong."> */}
          It looks like this page doesn&apos;t exist. Go back to homepage&nbsp;<Link href="/">here.</Link>
        {/* </ErrorLayout> */}
      </StyledError>
    </>    
  );

}


Error404Page.getLayout = (page: ReactElement) => {
  return (
    <MainLayout>
      {page}
    </MainLayout>
  )
}

export default Error404Page;

const StyledError = styled.div<{}>`

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  a{
    text-decoration: underline;

    &:hover{
      opacity: 0.8;
    }
  }

`


